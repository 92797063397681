import React, { useEffect, useRef, useState } from 'react';
import './collaborators.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate } from 'react-router-dom';
import { getCollectionFromDb, getUniqueDocumentFromDb } from '@/utils/getDataFromDb';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { sortPlayers } from '@/utils/sortPlayers';
import { properCase } from '@/utils/properCase';
import AddCollaboratorModal from './add-collaborator-modal.component';

const Collaborators = ({ 
    gameData,
    orgs,
    formData,
    saveData,
}) => {

    const [ selectedOrg, setSelectedOrg ] = useState(null);
    const [ orgMembers, setOrgMembers ] = useState(null);
    const [ gameRegs, setGameRegs ] = useState(null);
    const [ collabs, setCollabs ] = useState([]);
    const [ showAddCollabModal, setShowAddCollabModal ] = useState(false);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ newOrg, setNewOrg ] = useState(false);
    const approveRef = useRef();
    const membersRef = useRef();
    const regsRef = useRef();
    let membersListener;
    let regsListener;
    const navigate = useNavigate();

    useEffect(() => {

        return () => {
            if (membersListener) membersListener();
        }
    }, [])

    useEffect(() => {
        if (!orgs) return;

        // getOrgMembers();
    }, [orgs])

    useEffect(() => {
        if (!selectedOrg) return;
        if (selectedOrg.orgId) {
            getOrgMembers();
        }
    }, [selectedOrg])

    useEffect(() => {
        if (!formData) return;
        if (formData.gameId) {
            getGameRegs();
        }
        if (formData.orgId) {
            setSelectedOrg(formData.orgId);
        }
    }, [formData])

    useEffect(() => {
        if (!orgMembers || !gameRegs) return;
        let collabArr = []
        for (let appId of Object.keys(gameRegs.roles)) {
            if (!orgMembers[appId]) continue;
            if (gameRegs.roles[appId].length === 1 && gameRegs.roles[appId][0] === 'player') continue;
            const member = orgMembers[appId];
            const role = gameRegs.roles[appId][0];
            const order = 
                role === 'owner' ? 0 :
                role === 'manager' ? 1 : 2;
            const obj = {
                'fName': member.fName,
                'lName': member.lName,
                'icon': member.icon,
                'gradient': member.gradient,
                'appId': appId,
                'role': role,
                'order': order
            }
            collabArr.push(obj);
        }
        setCollabs(sortPlayers({
            'playerList': collabArr
        }));
    }, [orgMembers, gameRegs])

    function getOrgMembers() {
        console.log(selectedOrg.orgId);
        getCollectionFromDb({
            'collection': 'orgMemberDocs',
            'sort1': {
                'key': 'orgId',
                'value': selectedOrg.orgId,
            },
            'callback': callback,
            'handleListener': handleListener
        })
        function callback(data) {
            membersRef.current = {...membersRef.current, ...data.members};
            setOrgMembers(membersRef.current);
        }
        function handleListener(unsub) {
            membersListener = unsub;
        }
    }

    function getGameRegs() {
        console.log('getting game regs: ' + formData.gameId)
        getUniqueDocumentFromDb({
            'collection': 'regs',
            'docId': formData.gameId,
            'callback': callback,
            'handleListener': handleListener 
        })
        function callback(data) {
            regsRef.current = {...data};
            setGameRegs(regsRef.current);
        }
        function handleListener(unsub) {
            regsListener = unsub;
        }
    }

    function saveOrgChange(orgId) {
        if (formData.orgId && formData.orgId !== orgId) {
            setConfirmMessage('Are you sure you want to change the Organization from which you can add collaborators? Games can only be shared within one organization.<br /><br />Once you save the changes, this will remove from the game any existing collaborators from your former organization.')
            approveRef.current = () => {
                setNewOrg(true);
                if (orgId) {
                    continueSaveOrgChange();
                } else {
                    saveData('orgId', null);
                }                
            }
        } else {
            continueSaveOrgChange();
        }

        async function continueSaveOrgChange() {
            saveData('orgId', orgId);
            let tempRegs = {...gameRegs};
            let owner;
            let tempRoles = {...tempRegs.roles};
            for (let appId of Object.keys(tempRoles)) {
                if (tempRegs.roles[appId].includes('owner')) {
                    owner = appId;
                    continue; 
                }
                if (tempRegs.roles[appId].includes('player')) {
                    continue;
                }
                delete tempRoles[appId];
            }
            if (!owner) {
                setAlertMessage('Sorry, something went wrong. Try again later.');
                return;
            }
            tempRegs.list = [owner];
            tempRegs.roles = {...tempRoles}
            if (orgId) {
                setSelectedOrg(orgs[orgId]);
            }
            regsRef.current = {...tempRegs};
            setGameRegs(regsRef.current);
        }
    }

    async function saveCollabSettings() {
        const res = await UseCloudFunction(
            'saveGame', 
            {
                'gameObj': newObj, 
                'appId': profile.appId, 
                'action': 'save collabs'
            }
        )
        if (res.error) {
            setAlertMessage(`Something went wrong. Please try again later. (Error:${res.error})`);
            setLoading(false);
            return;
        }
        setAlertMessage('Settings saved successfully!')
        setLoading(false);
    }

    return (
        <div className='collaborators'>
            {
                (formData) &&
                <div className='g-card collab-card'>
                    <BackButton cancel={() => navigate(-1)} />
                    <div className="g-space-1"></div>
                    <div className='card-title'>Settings: Collaborators</div>
                    <div className="g-space-1"></div>
                    <div className='g-list-item grey-background'>
                        <div className="g-space-1"></div>
                        {
                            (formData.orgId && orgs[formData.orgId])
                            ?
                            <div className='org-box field'>
                                <label>Chosen Organization</label>
                                <div className='g-list-item'>
                                    <div className='card-title'>
                                        <ImageContainer 
                                            src={orgs[formData.orgId].icon ?? '/images/icons/placeholder-icon-org.jpg'}
                                            className='head-icon' />
                                        {orgs[formData.orgId].name}
                                    </div>
                                    <div className='change'>
                                        <button 
                                            className='g-button text-only' 
                                            onClick={() => saveOrgChange()} >
                                            Change
                                        </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='field small'>
                                <label>Choose an Organization</label>
                                <select 
                                    className='med-input'
                                    value={formData.orgId ?? ''} 
                                    onChange={(e) => saveOrgChange(e.target.value)}>
                                    <option value=''>Choose...</option>
                                    {
                                        (orgs && Object.values(orgs).length > 0) &&
                                        Object.values(orgs)
                                        .sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                                        .map(org => (
                                            <option 
                                                key={org.orgId} 
                                                value={org.orgId}>
                                                {org.name}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>
                        }
                        <div className='g-space-1'></div>
                        {
                            (formData.orgId) &&
                            <div className='field'>
                                <label>Collaborators</label>
                                <div className='g-list-item collab-list'>
                                    <button 
                                        className='g-button med-btn'
                                        onClick={() => setShowAddCollabModal(true)} >
                                        Add Collaborator
                                    </button>
                                    <div className="g-space-1"></div>
                                    {
                                        (collabs.length > 0)
                                        ?
                                        collabs.sort((a,b) => a.order > b.order ? 1 : -1)
                                        .map(c => (
                                            <div
                                                key={c.appId} 
                                                className='collab-list-item'>
                                                <div>{c.lName}, {c.fName}</div>
                                                <button
                                                    className={`g-button text-only ${c.role === 'owner' ? 'disabled' : ''}`}>
                                                    {properCase(c.role)}
                                                </button>
                                            </div>
                                        ))
                                        :
                                        <div>No collaborators yet...</div>
                                    }
                                </div>
                            </div>
                        }
                        <div className="g-space-1"></div>
                    </div>
                    <div className="g-space-1"></div>
                    <div className='buttons'>
                        <button className='g-button' onClick={() => navigate(-1)}>Cancel</button>
                        <button className='g-button primary' onClick={() => saveCollabSettings()}>Save Changes</button>
                    </div>
                </div>
            }
            
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
            <ModalConfirm
                show={confirmMessage}
                cancel={() => setConfirmMessage(null)}
                message={confirmMessage}
                onApprove={approveRef.current} />
            <AddCollaboratorModal
                gameData={gameData}
                showAddCollabModal={showAddCollabModal}
                setShowAddCollabModal={setShowAddCollabModal}
                orgMembers={orgMembers}
                collabs={collabs} />                
        </div>
    )
}

export default Collaborators