import React, { useEffect, useRef, useState } from 'react';
import './add-collaborator-modal.styles.scss';
import Modal from '@/components/modal/modal.component';
import { sortPlayers } from '@/utils/sortPlayers';
import SearchBar from '@/components/SHARED/search-bar/search-bar.component';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';

const AddCollaboratorModal = ({ 
    gameData,
    showAddCollabModal,
    setShowAddCollabModal,
    orgMembers,
    collabs
 }) => {

    const [ collabsToDisplay, setCollabsToDisply ] = useState([]);
    const membersRef = useRef();

    useEffect(() => {
        if (!collabs || !orgMembers) return;
        const existingCollabs = collabs.map(c => c.appId);
        console.log(existingCollabs);
        let orgMemberArr = []
        Object.values(orgMembers).map(m => {
            if (existingCollabs.includes(m.appId)) return;
            orgMemberArr.push(m);
        })
        console.log(orgMemberArr);
        setCollabsToDisply(sortPlayers({'playerList': orgMemberArr}));
        membersRef.current = [...orgMemberArr];
    }, [collabs, orgMembers])

    function search(text) {

    }

    return (
        <div className='add-collaborator-modal'>
            <Modal 
                show={showAddCollabModal}
                cancel={() => setShowAddCollabModal(false)}
                text='Add Collaborators'
                closeButton={true}
                cls='narrow' >
                    <div className='search-bar'>
                        <SearchBar
                            search={search} />
                    </div>
                    <div className="g-space-1"></div>
                    <div className='collab-list'>
                    {
                        (collabsToDisplay.length > 0)
                        ?
                        sortPlayers({'playerList': collabsToDisplay})
                        .filter(m => !collabs.includes(m))
                        .map(m => (
                            <div key={m.appId} className='g-list-item collab-item'>
                                <UserIcon profile={m} small={true} hover={false} />
                                <div>{m.fName} {m.lName}</div>
                                <div>
                                    <div className="field small">
                                        <select className='short-input'>
                                            <option value=''>Add Role</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        <div></div>
                    }
                    </div>
            </Modal>
        </div>
    )
}

export default AddCollaboratorModal