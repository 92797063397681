import React, { useEffect, useState } from 'react'
import './my-org-view.styles.scss';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { useNavigate, useParams } from 'react-router-dom';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { useSelector } from 'react-redux';
import { ModalAlert } from '@/components/modal/modal.component';

const MyOrgView = ({ orgs }) => {

    const userProfile = useSelector(state => state.userData.userProfile);
    const { orgPath } = useParams();
    const [ currentOrg, setCurrentOrg ] = useState(null);
    const [ currentMembers, setCurrentMembers ] = useState([]);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!orgPath || !orgs) return;
        const selectedOrg = Object.values(orgs)
        .filter(o => o.path === orgPath)[0];
        if (!selectedOrg) {
            navigate('/home/myorgs');
            return;
        }
        setCurrentOrg({...selectedOrg});
    }, [orgPath, orgs])

    useEffect(() => { 
        if (!currentOrg) return;
        getOrgMembers();
    }, [currentOrg])

    async function getOrgMembers() {
        const res = await UseCloudFunction(
            'getOrgMembers',
            {
                'orgId': currentOrg.orgId,
                'appId': userProfile.appId,
            }
        )
        console.log(res);
        if (res.error) {
            if (res.error === 'no access') {
                navigate(-1);
                return;
            }
            setAlertMessage('Something went wrong: ' + res.error);
            return;
        }
        const sortedMembers = Object.values(res.members)
        .sort((a,b) => 
            a.lName.toLowerCase() > b.lName.toLowerCase() ? 1 : 
            (a.lName.toLowerCase() === b.lName.toLowerCase()) ?
                a.fName.toLowerCase() > b.fName.toLowerCase() ?
                1 : -1
            : -1
        )
        setCurrentMembers([...sortedMembers]);
        setLoading(false);
    }

    return (
        <div className='my-org-view'>
            <div className='g-card'>
                <BackButton cancel={() => navigate(-1)} />
                <div className='g-space-1'></div>
                {
                    (currentOrg) &&
                    <div className='my-org-view-content'>
                        <div className='card-title'>
                            <ImageContainer 
                                src={currentOrg.icon ?? '/images/icons/placeholder-icon-org.jpg'}
                                className='head-icon' />
                            {currentOrg.name}
                        </div>
                        <hr />
                        <div>{currentOrg.name}</div>
                        <div>{currentOrg.address}</div>
                        <div>{currentOrg.address2}</div>
                        <div>{currentOrg.city}, {currentOrg.state} {currentOrg.zip}</div>
                        <div>{currentOrg.contactPhone}</div>
                        <div className='g-space-1'></div>
                        <div>Contact: <a href={currentOrg.contactEmail} targe='_blank'>
                            {currentOrg.contactName}
                        </a>
                        </div>
                        <div className='g-space-1'></div>
                        <h3>Current Members</h3>
                        {
                            (loading)
                            ?
                            <ThreeDotsLoader />
                            :
                            (currentMembers.length > 0) 
                            ?
                            <div className='member-list'>
                                {
                                    currentMembers.map(m => (
                                        <div key={m.appId} className='member-list-item'>
                                            <div>{m.lName}, {m.fName}</div>
                                        </div>
                                    ))
                                }
                            </div>
                            :
                            <div>No Members Yet...</div>
                        }

                    </div>
                }
            </div>
            <ModalAlert
                show={alertMessage}
                cancel={() => setAlertMessage(null)}
                message={alertMessage} />
        </div>
    )
}

export default MyOrgView