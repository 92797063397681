import React, { useEffect, useState } from 'react'
import './manage-landing.styles.scss';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import GameDashboard from './game-dashboard/game-dashboard.component';
import PlayerListContainer from './players/player-list-container.component';
import GameElements from './create-elements/create-elements-container.component';
import DistributePage from './distribute/distribute-page.component';
import GameSettings from './settings/game-settings.component';
import ActionItems from './action-items/action-items.component';
import LeaderboardsLandingPage from './leaderboards/leaderboard-landing-page.component';
import TeamsLandingPage from './teams/teams-landing.component';
import ManageStoreLanding from './marketplace/manage-store-landing.component';
import { RandomString } from '@/utils/randomString';
import { format } from 'date-fns';
import ManagerActivityList from './manager-activity-list/manager-activity-list.component';
import TransactionView from '../SHARED/tranaction-view/transaction-view.component';
import ActionItemsRealWorld from './action-items-realworld/action-items-realworld.component';

const ManageLanding = ({ 
    gameData, 
    gameElements, 
    playerList, 
    gameActivity, 
    loadMoreActivity, 
    actionItems, 
    gameStats,
    teamMembers,
    passcodes,
    latestMessage,
    orgs
}) => {

    const [ purchases, setPurchases ] = useState(null); 
    const { gamePath } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!gamePath || !gameData) return;
        // console.log(gamePath, gameData.path);
        if (gamePath !== gameData.path) {
            navigate('/404')
        }
    }, [gamePath, gameData])

    useEffect(() => {
        if (!location) return;
        document.getElementById('scroll-to-top').scroll({'top': 10, 'behavior': 'smooth'});
    }, [location])
    
    useEffect(() => {
        if (!gameActivity || !playerList) return;
        let purchasesObj = {}
        for (let p of Object.values(gameActivity).filter(p => p.type === 'purchase')) {
            if (!p.data || !playerList[p.playerId]) continue;
            let items = Object.values(p.data);
            for (let i of items) {
                let item = {
                    ...i,
                    ...{
                        'playerId': p.playerId,
                        'ts': p.ts,
                        'purchaseId': p.id,
                        'purchaseInstanceId': p.ts + RandomString(8),
                        'date': format(new Date(p.ts), "Pp"),
                        'fName': playerList[p.playerId].fName,
                        'lName': playerList[p.playerId].lName,
                        'info': playerList[p.playerId].info,
                        'path': p.path
                    }
                };
                if (item.title) {
                    item.name = item.title;
                    delete item.title;
                }
                purchasesObj[item.purchaseInstanceId] = item
            }
        }
        setPurchases({...purchasesObj});
    }, [gameActivity, playerList])

    return (
        <div className='manage-landing-content' id='scroll-to-top'>
            <Routes>
                <Route 
                    path='' 
                    element={
                        <GameDashboard 
                            gameData={gameData} 
                            gameActivity={gameActivity} 
                            playerList={playerList} 
                            loadMoreActivity={loadMoreActivity} 
                            gameElements={gameElements} 
                            latestMessage={latestMessage}
                            actionItems={actionItems}
                        />
                    }
                ></Route>
                <Route 
                    path='players/*' 
                    element={
                        <PlayerListContainer 
                            gameData={gameData}
                            gameElements={gameElements} 
                            playerList={playerList} 
                            gameActivity={gameActivity} 
                            gameStats={gameStats}
                            passcodes={passcodes}
                        />
                    }
                ></Route>
                <Route 
                    path='teams/*' 
                    element={
                        <TeamsLandingPage 
                            gameData={gameData} 
                            playerList={playerList} 
                            gameElements={gameElements} 
                        />
                    }
                ></Route>
                <Route 
                    path='elements/*' 
                    element={
                        <GameElements 
                            gameData={gameData} 
                            gameElements={gameElements} 
                            playerList={playerList} 
                            gameStats={gameStats}
                            gameActivity={gameActivity}
                        />
                    }
                ></Route>
                <Route 
                    path='distribute' 
                    element={
                        <DistributePage 
                            gameData={gameData} 
                            gameElements={gameElements} 
                            playerList={playerList} 
                        />
                    }
                ></Route>
                <Route 
                    path='settings/*' 
                    element={
                        <GameSettings 
                            gameData={gameData} 
                            playerList={playerList} 
                            orgs={orgs}
                        />
                    }
                ></Route>
                <Route 
                    path='actionitems/*' 
                    element={
                        <ActionItems 
                            gameData={gameData} 
                            gameElements={gameElements} 
                            playerList={playerList} 
                            actionItems={actionItems}
                        />
                    }
                ></Route>
                <Route 
                    path='realworlddist/*' 
                    element={
                        <ActionItemsRealWorld 
                            gameData={gameData} 
                            gameElements={gameElements} 
                            playerList={playerList} 
                            actionItems={actionItems}
                        />
                    }
                ></Route>
                <Route 
                    path='leaderboards/*' 
                    element={
                        <LeaderboardsLandingPage 
                            gameData={gameData} 
                            playerList={playerList} 
                            gameElements={gameElements} 
                        />
                    }
                ></Route>
                <Route 
                    path='store/*' 
                    element={
                        <ManageStoreLanding 
                            gameData={gameData} 
                            playerList={playerList} 
                            gameElements={gameElements} 
                            gameActivity={gameActivity}
                            purchases={purchases}
                            gameStats={gameStats}
                        />
                    }
                ></Route>
                <Route 
                    path='activity'
                    element={
                        <ManagerActivityList
                            gameData={gameData} 
                            gameActivity={gameActivity} 
                            playerList={playerList} 
                            gameElements={gameElements} />
                    }
                ></Route>
                <Route
                    path='transaction/:transPath'
                    element={
                        <div className='transaction-container'> 
                            <div className='transaction-card g-card'>   
                                <TransactionView
                                    playerList={playerList}
                                    gameActivity={gameActivity}
                                    gameElements={gameElements} />
                            </div>
                        </div>
                    }
                ></Route>
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
        </div>
    )
}

export default ManageLanding