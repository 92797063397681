import React, { useEffect, useState } from 'react'
import './enrolled-list.styles.scss';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import ImageContainer from '@/components/SHARED/image-container/image-container.component';
import { format } from 'date-fns';

const EnrolledList = () => {

    const screenSize = useSelector(state => state.contentData.screenSize);
    const enrolledList = useSelector(state => state.contentData.enrolledList);
    const [ loading, setLoading ] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1500)
    }, [])

    return (
        <div className={`enrolled-list ${screenSize}`}>
            {
                (loading) 
                ?
                <>
                    <div></div>
                    <div className='center'><ThreeDotsLoader /></div>
                </>
                :
                (enrolledList)
                ?
                Object.values(enrolledList).sort((a,b) => a.lastActivity < b.lastActivity ? 1 : -1)
                .map(g => (
                    <button 
                        key={g.gameId} 
                        className='g-button clickable game-card'
                        onClick={() => navigate(`/play/${g.path}`)}>
                        <div className='game-header'>
                            <ImageContainer src={g.header} />
                        </div>
                        <div className='game-name'>{g.gameName}</div>
                        <div className='meta'>
                            Creator: {g.creatorName}<br />
                            Last Activity: {g.lastUserActivity ? format(new Date(g.lastUserActivity), 'P') : 'none'}
                        </div>
                    </button>
                ))
                :
                <div className='center-col'>
                    <h3>No games yet...</h3>
                </div>
            }
        </div>
    )
}

export default EnrolledList