import React, { useEffect, useRef, useState } from 'react';
import './add-player.styles.scss';
import { useNavigate } from 'react-router-dom';
import BackButton from '@/components/SHARED/back-button/back-button.component';
import { getRandomLinearGradient } from '@/utils/randomGradient';
import { RandomString } from '@/utils/randomString';
import { usePapaParse } from 'react-papaparse';
import { ModalAlert, ModalConfirm } from '@/components/modal/modal.component';
import { useSelector } from 'react-redux';
import UserIcon from '@/components/layout-auth/user-icon/user-icon.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTractor, faTrash } from '@fortawesome/free-solid-svg-icons';
import ThreeDotsLoader from '@/components/loader/three-dots-loader.component';
import { UseCloudFunction } from '@/utils/firebase.utils';
import { createAlias } from '@/utils/aliases';
import { membershipRestrictions } from '@/utils/membershipRestrictions';
import UpgradeRequiredModal from '@/components/SHARED/upgrade-required-modal/upgrade-required-modal.component';
import FullPageLoader from '@/components/loader/full-page-loader.component';

const AddPlayer = ({ gameData }) => {

    const membership = useSelector(state => state.userData.membership);
    const userProfile = useSelector(state => state.userData.userProfile);
    const [ alertMessage, setAlertMessage ] = useState(null);
    const [ confirmMessage, setConfirmMessage ] = useState(null);
    const [ objToUpload, setObjToUpload ] = useState({});
    const [ formData, setFormData ] = useState({});
    const [ editing, setEditing ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ spotsAvailable, setSpotsAvailable ] = useState(0);
    const [ showUpgradeModal, setShowUpgradeModal ] = useState(false);
    const { readString } = usePapaParse();
    const fileReader = new FileReader();
    const approveRef = useRef();
    const dataRef = useRef();
    const timeoutRef = useRef();

    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        }
    }, [])

    useEffect(() => {
        if (!gameData || !membership) return;
        const spots = membershipRestrictions[membership.membership].playerCount - (gameData.playerCount ?? 0);
        setSpotsAvailable(spots);
        if (membershipRestrictions[membership.membership].playerCount <= (gameData.playerCount ?? 0)) {
            const planRest = membershipRestrictions[membership.membership];
            const message = `While using the ${planRest.title}, you are only able to have ${planRest.playerCount} active Players/Teams per game.`;
            setShowUpgradeModal(message);
        }
    }, [gameData, membership])

    function clickUpload() {
        document.getElementById('fileUpload').click();
    }

    function saveData(key, value) {
        dataRef.current = {...dataRef.current, ...{[key]: value}};
        setFormData(dataRef.current);
    }

    async function handleUpload(file) {
        const ext = file.name.match(/\.([^\.]+)$/)[1].toLowerCase();
        console.log(ext);
        const allowed = ['csv']
        if (!allowed.includes(ext)) {
            setAlertMessage('That file type is not allowed to be uploaded. Try a different file.');
            document.getElementById('fileUpload').value = null;
            return;
        }
        const csvOutput = await readFileAsync(file)
		// console.log(csvOutput)
		const arr = await getParsedData(csvOutput)
        console.log(arr);
		const listObj = createObjs(arr)
        console.log(listObj);
        if (listObj) {
            setObjToUpload({...objToUpload, ...listObj});
            document.getElementById('fileUpload').value = null;
        }
        setAlertMessage('Your upload processed successfully and players are ready to be created! Scroll down to finalize and upload your list of players.')
        scrollDown();
    }

    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            fileReader.onload = (event) => {
                resolve(event.target.result);	
            };
            fileReader.readAsText(file);
        })
    }

    function getParsedData(arr) {
		return new Promise((resolve, reject) => {
			readString(arr, {
		      	worker: true,
		      	complete: (results) => {
			        // console.log('---------------------------');
			        // console.log(results.data);
			        // console.log('---------------------------');
					resolve(results.data)
			  	},
		    });
		})
	}

    function createObjs(arr) {
        const template = [
            "First Name (Required)",
            "Last Initial, number, etc. (optional)",
            "Additional Information (optional)",
            "Password (optional)",
        ]
		const headers = arr[0]
        console.log(template);
        console.log(headers);
        let validCSV = true;
        for (let i=0; i<template.length; i++) {
            if (template[i] !== headers[i]) {
                validCSV = false;
                break;
            }
        }
		if (!validCSV) {
            setAlertMessage('Please use the correct template when uploading a list of Players.');
            return false;
        }
        let listObj = {};
        const now = new Date().getTime();
        const len = arr.length <= spotsAvailable ? arr.length : spotsAvailable;
		for (let a=1; a<len; a++) {
			const row = arr[a];
            const appId = RandomString(24);
            let passcode = '';
            for (let a=0; a<4; a++) {
                passcode += Math.ceil(Math.random() * 9)
            }
            console.log(!!row[3])
            console.log(passcode);
			let obj = {
                'appId': appId,
                'currency': 0,
                'currencyTotal': 0,
                'alias': createAlias(),
                'displayName': `${row[0]}${row[1] ? ` ${row[1][0]}` : ''}`,
                'enroll_method': 'upload',
                'fName': row[0],
                'gameId': gameData.gameId,
                'gradient': getRandomLinearGradient(),
                'icon': '',
                'playerId': `${gameData.gameId}_${appId}`,
                'info': row[2],
                'lName': row[1],
                'path': RandomString(18, 'abcdefghijkmnopqrstuvwxyz-_023456789'),
                'passcode': !!row[3] ? row[3] : passcode,
                'points': 0,
                'status': 'active',
                'ts': now,
                'ts_joined': now,
            }
            listObj[obj.playerId] = obj;
		}
        console.log(listObj);
		return listObj;
	}

    function addPlayer() {
        if (!formData.fName) return;
        const now = new Date().getTime();
        const appId = RandomString(24);
        let passcode = '';
        for (let a=0; a<4; a++) {
            passcode += Math.ceil(Math.random() * 9)
        }
        let obj = {
            'appId': formData.appId ?? appId,
            'currency': 0,
            'currencyTotal': 0,
            'displayName': `${formData.fName}${formData.lName ? ` ${formData.lName[0]}` : ''}`,
            'enroll_method': 'upload',
            'fName': formData.fName,
            'gameId': gameData.gameId,
            'gradient': formData.gradient ?? getRandomLinearGradient(),
            'icon': '',
            'playerId': `${gameData.gameId}_${formData.appId ?? appId}`,
            'info': formData.info ?? '',
            'lName': formData.lName ?? '',
            'passcode': formData.passcode ?? passcode,
            'path': RandomString(18, 'abcdefghijkmnopqrstuvwxyz-_023456789'),
            'points': 0,
            'alias': createAlias(),
            'status': 'active',
            'ts': now,
            'ts_joined': now,
        }
        let tempObj = {...objToUpload}
        tempObj[obj.playerId] = obj;
        setObjToUpload(tempObj);
        console.log(tempObj);
        dataRef.current = {}
        setFormData(dataRef.current);
        setEditing(false);
        setAlertMessage('Your player was added to the list of Players to Create! Scroll down to finalize and Create your list of players.')
        scrollDown();
    }

    function scrollDown() {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            const scrollDiv = document.getElementById('scroll-to-top')
            scrollDiv.scrollBy({ top: 600, behavior: 'smooth' });
        }, 250);
    }

    function editPlayer(id) {
        setEditing(true);
        dataRef.current = {...objToUpload[id]};
        setFormData(dataRef.current);
    }

    function removePlayer(id) {
        let tempData = {...objToUpload};
        delete tempData[id];
        dataRef.current = {...tempData};
        setObjToUpload(dataRef.current);
    }

    function cancelUpload(finished) {
        if (!finished && Object.values(objToUpload).length > 0) {
            setConfirmMessage('Are you sure you want to cancel this player creation session? All progress will be lost.')
            approveRef.current = () => {
                cancel();
            }
        } else {
            cancel();
        }

        function cancel() {
            setObjToUpload({});
            document.getElementById('fileUpload').value = null;
            navigate(-1);
        }
    }

    function proceedWithUpload() {
        setConfirmMessage(`Are you sure you are ready to upload ${Object.keys(objToUpload).length} Players?`);
        approveRef.current = async () => {
            setLoading(true);
            const res = await UseCloudFunction('uploadPlayers', {'playerArr': Object.values(objToUpload), 'appId': userProfile.appId, 'gameId': gameData.gameId, 'userName': `${userProfile.fName} ${userProfile.lName}`})
            if (res.error) {
                setAlertMessage('Something went wrong. Please try again later. Details: ' + res.error);
                return;
            }
            setLoading(false);
            cancelUpload(true);
        }
    }

    return (
        <div className='add-player-container'>
            <div className='add-player-content'>
                <div className='g-card'>
                    <BackButton cancel={cancelUpload} />
                    <div className="g-space-1"></div>
                    <div className='card-title'>Add New Players</div>
                    <hr />
                    <div className='card-title'>
                        <p>Upload a List of Players</p>
                        {
                            (membership.membership <= 2) &&
                            <p className='meta'>{spotsAvailable} Spots Available</p>
                        }
                    </div>
                    
                    <p>You can either upload a roster or manually enter each participant. 
                        To upload a roster, make a copy of <a href="https://docs.google.com/spreadsheets/d/1NnCXblmLUvK-QiUSGaKlNHInbS6nj4m9IPOR2L5-RU4/copy" target="_blank"><b>THIS GOOGLE SHEET</b></a> and export 
                        it as a CSV or download THIS CSV. Then, upload the 
                        completed roster here.
                    </p>
                    <div style={{cursor: 'pointer'}}>
                        <input type="file" id="fileUpload" accept=".csv" onChange={(e) => handleUpload(e.target.files[0])}  />
                        <button className="g-button" type="button" tabIndex="-1" onClick={() => clickUpload()}>Upload a List of Players</button>
                    </div>
                    <div className='g-space-1'></div>
                    <h3>OR</h3>
                    <h3>Add Players Individually</h3>
                    <div className='g-double-col'>
                        <div className='field small'>
                            <label>First Name (required)</label>
                            <input type='text' value={formData.fName ?? ''} onChange={(e) => saveData('fName', e.target.value)} />
                        </div>
                        <div className='field small'>
                            <label>Last Initial, number, etc. (optional)</label>
                            <input type='text' value={formData.lName ?? ''} onChange={(e) => saveData('lName', e.target.value)} />
                        </div>
                    </div>
                    <div className='g-space-1'></div>
                    <div className='g-double-col'>
                        <div className='field small'>
                            <label>Additional Information (optional)</label>
                            <input type='text' value={formData.info ?? ''} onChange={(e) => saveData('info', e.target.value)} />
                        </div>
                        <div className='field small'>
                            <label>Passcode (optional)</label>
                            <input type='text' value={formData.passcode ?? ''} onChange={(e) => saveData('passcode', e.target.value)} />
                        </div>
                    </div>
                    <div className='g-space-1'></div>
                    <div className='buttons'>
                        <button className='g-button' onClick={() => addPlayer()}>{editing ? 'Save Changes' : 'Add Player to List'}</button>
                    </div>
                    <div className="g-space-1"></div>
                    {
                        (Object.values(objToUpload).length > 0) &&
                        <>
                        <h3>Players To Create</h3>
                        <div className='upload-item header'>
                            <div className='info'></div>
                            <div className='info'>Name</div>
                            <div className='info'>Info</div>
                            <div className='info'>Passcode</div>
                            <div className='buttons info'>Actions</div>
                        </div>
                        <div className='g-list-item upload-list'>
                            {
                                Object.values(objToUpload).map(p => (
                                    <div key={p.playerId} className='upload-item'>
                                        <UserIcon profile={p} noHover={true} />
                                        <div className='name'>{p.fName} {p.lName}</div>
                                        <div className='info'>{p.info}</div>
                                        <div className='passcode'>{p.passcode}</div>
                                        <div className='buttons'>
                                            <button className='g-button med-btn' onClick={() => editPlayer(p.playerId)}><FontAwesomeIcon icon={faPen} /></button>
                                            <button className='g-button med-btn' onClick={() => removePlayer(p.playerId)}><FontAwesomeIcon icon={faTrash} /></button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className='g-space-1'></div>
                        {
                            (loading)
                            ?
                            <div className='buttons'>
                                <button className='g-button'>Cancel</button>
                                <button className='g-button'><ThreeDotsLoader /></button>
                            </div>
                            :
                            <div className='buttons'>
                                <button className='g-button' onClick={() => cancelUpload()}>Cancel</button>
                                <button className='g-button primary' onClick={() => proceedWithUpload()}>Create These {Object.values(objToUpload).length} Players</button>
                            </div>
                        }
                        </>
                    }
                </div>
            </div>
            <div className="g-space-2"></div>
            <ModalAlert show={alertMessage} cancel={() => setAlertMessage(null)} message={alertMessage} />
            <ModalConfirm show={confirmMessage} cancel={() => setConfirmMessage(null)} message={confirmMessage} onApprove={approveRef.current} />
            <UpgradeRequiredModal
                show={showUpgradeModal}
                cancel={() => setShowUpgradeModal(false)}
                closeButton={false}
                message={showUpgradeModal}
            />
            <FullPageLoader show={loading} />
        </div>
    )
}

export default AddPlayer