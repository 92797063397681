export const membershipRestrictions = [
    {
        "title": "Free Plan",
        "gameCount": 2,
        "playerCount": 50,
        "elementCount": 200,
        "blocked": [
            "prizePacks",
            "collections",
            "quests",
            "tradePortal",
        ]
    },
    {
        "title": "Pro Plan",
        "gameCount": 10,
        "playerCount": 200,
        "elementCount": 200,
        "blocked": [
            "quests"
        ]
    },
    {
        "title": "Premium Plan",
        "gameCount": 10,
        "playerCount": 1000,
        "blocked": []
    },
    {
        "title": "Enterprise Plan",
        "gameCount": Infinity,
        "playerCount": Infinity,
        "blocked": []
    }
]

export function testMemberRestrictions(e) {
    const membership = e.membership;
    const memNum = membership.membership;
    const restrict = membershipRestrictions[memNum];
    let reasonArr = [];
    let newGame = {};

    if (e.gameCount) {
        const existingGameCount = e.gameCount;
        
        if (existingGameCount && existingGameCount >= restrict.gameCount) {
            newGame.disabled = true;
            reasonArr.push('games');
        }
        newGame.disabledReason = reasonArr;
        return newGame;
    }
    
    const game = e.gameData;
    const gameIndex = e.gameIndex;
    const create = e.create;
    const element = e.element;
    newGame = {...game}
    if (!restrict) return newGame;
    
    // console.log(index);
    

    // test on "Games I Manage" list
    if (gameIndex && gameIndex > restrict.gameCount) {
        newGame.disabled = true;
        reasonArr.push('games');
    }

    if (element && restrict.blocked.includes(element)) {
        newGame.disabled = true;
        reasonArr.push(element);
    }

    // test game-specific restrictions

    if (game) {
        // test against playerCount
        if (create) {
            if (restrict.playerCount && game.playerCount >= restrict.playerCount) {
                newGame.disabled = true;
                reasonArr.push('players');
            }
        } else {
            if (restrict.playerCount && game.playerCount > restrict.playerCount) {
                newGame.disabled = true;
                reasonArr.push('players');
            }
        }

        // test against elementCount
        if (create) {
            if (restrict.elementCount && game.createdElementTotal >= restrict.elementCount) {
                newGame.disabled = true;
                reasonArr.push('elements');
            }
        } else {
            if (restrict.elementCount && game.createdElementTotal > restrict.elementCount) {
                newGame.disabled = true;
                reasonArr.push('elements');
            }
        }
    }

    if (reasonArr.length > 0) {
        newGame.disabledReason = reasonArr;
    }
    return newGame;
}
